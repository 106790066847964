import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projets`}</h2>
    <ProjectCard title="Projet fin d'année de l'EPSI " link="https://github.com/nderhore/ApplicationMobile" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Application mobile hybride réalisée en react Native avec expo.<br /> Test effectué via Cypress, Jersey, CI via gitlab.<br />
Back effectué en Java 11 avec Spring boot et hibernate, la base de donnée est dans un docker.
    </ProjectCard>
    <ProjectCard title="Projet Angular" link="https://github.com/nderhore/StarWars" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Application en Angular 8 interogeant une API referençant les films Star Wars.
    </ProjectCard>
    <ProjectCard title="Projet Angular et Java" link="https://github.com/nderhore/catalog" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Application en Angular 8 interogeant l'API en Java 11 avec Spring boot et hibernate.<br />
Elle permet de gerer des produits, les stocks.
    </ProjectCard>
    <ProjectCard title="Domotique" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Divers projet réalisé avec un raspberry (jeedom) et un arduino : <br />
      <ul>
    <li>Cafetiere connectée</li>
    <li>Serrure connectée</li>
    <li>Relevé de température</li>
    <li>Arrosage automatique (via un capteur d'humidité)</li>
      </ul>
    </ProjectCard>
    <ProjectCard title="Application Immobilière" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Application immobilière demandé par une entreprise. Application complète en : <br />
      <ul>
    <li>Front en VueJS</li>
    <li>Back en Java 11 LTS avec Spring Boot / hibernate / Spring Cloud</li>
    <li>SGBD PostgreSQL deployé dans un docker</li>
    <li>Déployé sur mon NAS (docker-compose)</li>
      </ul>
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      