import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`A propos`}</h2>
    <p>Etudiant de 24 ans admis en master DevOps à Efficom.<br />
Je suis titulaire d'un bac +3 DevOps au sein de l'epsi, d'un bac +2 BTS SIO SLAM ainsi que d'un Bac S option SVT Spécialité ISN.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      